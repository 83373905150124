import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LoginModel, UserResponse} from '../models/login/login.model';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url: string;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.url = environment.api;
  }

  login(user: LoginModel) {
    return this.http.post(`${this.url}/admin/login/`, user)
      .pipe(
        map((userResponse: UserResponse) => {
          localStorage.setItem('currentUser', JSON.stringify(userResponse));
          return userResponse;
        })
      );
  }

  sendToken(body){
    return this.http.post(`${this.url}/notifications/tokens/`, body)
    .pipe(
      map(
        token => token 
      )
    )
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
